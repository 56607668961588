<template>
    <div>
        <div class="card-toolbar mb-5">

            <button v-if="$can('available_sub_features.create')" @click="showModal" class="btn btn-primary font-weight-bolder">
                <v-icon>mdi-plus</v-icon>
                {{ $t('available_features.new_available_sub_feature') }}
            </button>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="name">{{$t('available_features.name')}}</label>
                            <input v-model="filters.name" type="text" id="name" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="key">{{$t('available_features.key')}}</label>
                            <input v-model="filters.key" type="text" id="key" class="form-control">
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">
                                {{$t('status')}}
                            </label>
                            <select name="" id="f_status" v-model="filters.is_active" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
                            </select>
                        </div>
                        <div class="form-group d-inline-flex col-md-6 mt-10">
                            <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                <span><i class="fas fa-search"></i>
                                    <span>{{$t('search')}}</span>
                                </span>
                            </button>
                            <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                                <span><i class="fas fa-trash-restore"></i>
                                    <span>{{$t('reset_search')}}</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-body">
                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="status" slot-scope="props">
                        <b-form-checkbox v-if="$can('available_sub_features.change_status')" size="lg" @change="changeStatus(props.row.id, props.row.is_active)" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                        <b-form-checkbox v-else :disabled="true" size="lg" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-icon v-if="$can('available_sub_features.update')" small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" color="blue darken-2" @click="showModal(props.row)">mdi-pencil</v-icon>
                        <v-icon v-if="$can('available_sub_features.delete')" small class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" color="red darken-2" @click="deleteItem(props.row)">mdi-delete</v-icon>
                    </template>
                </v-server-table>


                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->


        <b-modal ref="modal" hide-footer :title="$t('available_features.available_sub_features')">
            <div class="form-group">
                <label for="name">{{$t('available_features.name')}}</label>
                <input v-model="item.name" type="text" class="form-control" :placeholder="$t('available_features.name')">
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.name[0] }}
                </span>
            </div>

            <div class="form-group">
                <label for="key">{{$t('available_features.key')}}</label>
                <input v-model="item.key" type="text" class="form-control" :placeholder="$t('available_features.key')">
                <span v-if="validation && validation.key" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.key[0] }}
                </span>
            </div>
            <div class="form-group">
                <label for="value">{{$t('available_features.description')}}</label>
                <textarea v-model="item.description" type="text" class="form-control" id="value" :placeholder="$t('available_features.description')"></textarea>
                <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.description[0] }}
                </span>
            </div>

            <div class="form-group">
                <b-form-checkbox size="lg" v-model="item.is_active" name="check-button" switch>{{$t('status')}}</b-form-checkbox>
            </div>

            <div class="d-flex justify-content-end">
                <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{$t('close')}}</b-button>
                <b-button class="mt-2 mr-1" variant="primary" @click="save">{{$t('save')}}</b-button>
            </div>
        </b-modal>

    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        components: {},
        data() {
            return {
                mainRoute: '/settings/available_features',
                subMainRoute: '/settings/available_feature',
                mainRouteDependency: 'base/dependency',
                showAdvancedSearch: false,

                filters: {
                    name: null,
                    key: null,
                    is_active: null,
                    parent_id: this.$route.params.id ? this.$route.params.id : '',
                },

                status_list: [
                    {id: 1, text: this.$t('active')},
                    {id: 0, text: this.$t('inactive')},
                ],
                columns: ['name', 'key', 'created_at', 'status', 'actions'],

                dataList: [],


                item: {
                    id: null,
                    name: null,
                    key: null,
                    description: null,
                    parent_id: this.$route.params.id ? this.$route.params.id : '',
                    is_active: true,
                },
                validation: [],
                isEditing: false,

            }
        },
        watch:{
            "item.key": function (val) {
                if (val){
                    this.item.key = val.replace(/[^a-zA-Z0-9 ]/g, '_').replace(' ', '_');
                }
            }
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        name: that.$t('available_features.name'),
                        key: that.$t('available_features.key'),
                        created_at: that.$t('created_at'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),

                    },
                    sortable: ['created_at'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'created_at'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: 0,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },

        mounted() {
            // let that = this;
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.available_sub_features"), route:{name: 'available-features.index'}}, {title: this.$t("MENU.available_sub_features")}]);
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.name = null;
                this.filters.key = null;
                this.filters.is_active = null;
                this.filters.parent_id = this.$route.params.id ? this.$route.params.id : '';

                this.$refs.table.refresh();
            },
            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            getData(id) {
                ApiService.get(`${this.mainRoute}/${id}`).then((response) => {
                    this.isEditing = true;
                    this.item.name = response.data.data.name;
                    this.item.key = response.data.data.key;
                    this.item.description = response.data.data.description;
                    this.item.parent_id = response.data.data.parent_id;
                    this.item.is_active = response.data.data.is_active;

                });
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            changeStatus(id, status) {
                ApiService.patch(this.subMainRoute + '/change-status/' + id, {
                    is_active: (status ? '1' : '0'),
                }).then(response => {
                    this.$refs.table.refresh();
                    this.$successAlert(response.data.message);
                }).catch(error => {
                    this.$errorAlert(error);
                });
            },

            showModal(data) {
                if (!data.id) {
                    this.reset();
                    this.$refs['modal'].show()
                } else {
                    this.isEditing = true;
                    this.id = data.id;
                    this.getData(data.id);
                    this.$refs['modal'].show();
                }
            },
            reset() {
                this.item = {
                    id: null,
                    name: null,
                    key: null,
                    parent_id: this.$route.params.id ? this.$route.params.id : '',
                    description: null,
                    is_active: true,
                };
                this.validation = [];
                this.isEditing = false;
            },
            afterSave() {
                this.getFetch();
                this.item = {
                    id: null,
                    name: null,
                    key: null,
                    parent_id: this.$route.params.id ? this.$route.params.id : '',
                    description: null,
                    is_active: true,
                };
                this.validation = [];
                this.isEditing = false;

            },
            hideModal() {
                this.$refs['modal'].hide();
                this.afterSave();
            },

            save() {
                if (this.isEditing) {
                    this.saveEdit();
                } else {
                    this.saveCreate();
                }
            },

            saveCreate() {
                ApiService.post(this.mainRoute, this.item).then((response) => {
                    this.$successAlert(response.data.message);
                    this.afterSave();
                    this.hideModal();
                }).catch((error) => {
                    this.$errorAlert(error);
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },
            saveEdit() {
                ApiService.put(`${this.mainRoute}/${this.id}`, this.item).then((response) => {
                    this.$successAlert(response.data.message);
                    this.afterSave();
                    this.hideModal();
                }).catch((error) => {
                    this.$errorAlert(error);
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },
        },
    };
</script>
